import { cloneDeep, isEqual } from 'lodash'

const modelMixin = {
  props: {
    value: {
      type: [Array, Object, String, Number],
      required: true
    }
  },
  data () {
    return {
      fields: cloneDeep(this.value)
    }
  },
  computed: {
    isEqual () {
      return isEqual(this.fields, this.value)
    }
  },
  watch: {
    value (value) {
      this.fields = cloneDeep(value)
    }
  }
}

export default modelMixin
